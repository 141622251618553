<script>
import store from "@/store";
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    collapse: {
      type: Boolean,
      default: false
    }
  },
  render(h, context) {
    const { icon, title, collapse } = context.props
    const vnodes = []

    if (icon) {
      if(collapse && title==='客户服务' && store.state.ticket.unRepliedCount> 0){
        vnodes.push(<el-badge is-dot class="dot-badge"><svg-icon icon-class={icon}/></el-badge>)
      }else{
        vnodes.push(<svg-icon icon-class={icon}/>)
      }
    }

    if (title) {
      if (title.length > 5) {
        vnodes.push(<span slot='title' title={(title)}>{(title)}</span>)
      } else {
        vnodes.push(<span slot='title'>{(title)}</span>)
      }
    }

    const unRepliedTicketCount = store.state.ticket.unRepliedCount;
    if( (title==='客户工单'||title==='客户服务') && unRepliedTicketCount> 0 ) {
      vnodes.push(<el-badge value={(unRepliedTicketCount)} style="float:right;margin-right:15px;"/>)
    }

    const notDealCount = store.state.innerTicket.notDealCount
    if( (title==='系统办公'||title==='内部工单') && notDealCount> 0 ) {
      vnodes.push(<el-badge value={(notDealCount)} style="float:right;margin-right:15px;"/>)
    }

    const packageCount = store.state.inbound.packageCount;
    if( (title==='入库包裹') && packageCount.waitReInboundCount> 0 ) {
      vnodes.push(<el-badge value={(packageCount.waitReInboundCount)} style="float:right;margin-right:-10px;"/>)
    }
    if( (title==='异常包裹') && packageCount.abnormalCount> 0 ) {
      vnodes.push(<el-badge value={(packageCount.abnormalCount)} style="float:right;margin-right:-10px;"/>)
    }

    const extraPhotoToHandleCount = store.state.valueAddService.menuStatistics.extraPhotoToHandleCount;
    if( (title==='精细拍照') && extraPhotoToHandleCount> 0 ) {
      vnodes.push(<el-badge value={(extraPhotoToHandleCount)} style="float:right;margin-right:-10px;"/>)
    }


    return vnodes
  }
}
</script>

<style lang="scss">

.dot-badge {
  .is-dot {
    top: 15px;
    right: 15px !important;
  }
}
</style>
