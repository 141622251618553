import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import tagsView from './modules/tagsView'
import permission from './modules/permission'
import settings from './modules/settings'
import dict from './modules/dict'
import getters from './getters'
import ticket from './modules/ticket'
import innerTicket from  './modules/innerTicket'
import countries from "./modules/countries"
import inbound from "./modules/inbound"
import valueAddService from "./modules/valueAddService"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    user,
    tagsView,
    permission,
    settings,
    dict,
    ticket,
    innerTicket,
    countries,
    inbound,
    valueAddService,
  },
  getters
})

export default store
