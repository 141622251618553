import { count } from '@/api/innerTicket/innerTicket';

const state = {
  notDealCount: 0,
}

const mutations = {
  SET_INNER_TICKET_NOT_DEAL_COUNT: (state, ticketDatas) => {
    state.notDealCount = ticketDatas
  }
}
const actions = {
  getNotDealCount({ commit }) {
    count({
      waitReply: true,
      status: 'PROCESSING'
    }).then(res => {
      if (!res || !res.data) {
        return;
      }
      commit('SET_INNER_TICKET_NOT_DEAL_COUNT', res.data);
    });
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
