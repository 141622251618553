import request from "@/utils/request";

export function readTicket(id){
  return request({
    url: '/inner-ticket/read',
    method: 'post',
    params: { id: id }
  })
}

export function createReply(data){
  return request({
    url: '/inner-ticket/reply',
    method: 'post',
    data: data
  })
}

export function cancelTicket(ticketId){
  return request({
    url: '/inner-ticket/cancel',
    method: 'put',
    params: { ticketId: ticketId }
  })
}

export function claimTicket(ticketId){
  return request({
    url: '/inner-ticket/claim',
    method: 'put',
    params: { ticketId: ticketId }
  })
}

export function claimTicketBatch(params){
  return request({
    url: '/inner-ticket/claim-batch',
    method: 'put',
    params: params
  })
}

export function assignTicket(params){
  return request({
    url: '/inner-ticket/assign',
    method: 'put',
    params: params
  })
}

export function assignTicketBatch(params){
  return request({
    url: '/inner-ticket/assign-batch',
    method: 'put',
    params: params
  })
}

export function completeTicket(ticketId){
  return request({
    url: '/inner-ticket/complete',
    method: 'put',
    params: { ticketId: ticketId }
  })
}

export function activeTicket(ticketId){
  return request({
    url: '/inner-ticket/active',
    method: 'put',
    params: { ticketId: ticketId }
  })
}

export function confirm(ticketId){
  return request({
    url: '/inner-ticket/confirm',
    method: 'put',
    params: { ticketId: ticketId }
  })
}

export function count(params){
  return request({
    url: '/inner-ticket/count',
    method: 'get',
    params: params
  })
}

export function exportInnerTicket(params){
  return request({
    url: '/inner-ticket/export-excel',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}
